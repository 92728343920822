import React, { Component } from 'react';
import { connect } from 'react-redux'

class Signs extends Component {
    constructor() {
        super();

        this.state = {
            categories: []
        }
    }

    componentDidMount() {
        // this.getCategories()
    }

    getSign = (id) => {
        fetch(`http://localhost:3000/lib_sign/${id}`)
            .then((response) => {
                return response.json()
            })
            .then((body) => {
                this.props.setSign(body)
            })
            .catch((err) => {
                console.log('errooooooooorrrrrrrr ====== ' + err)
            })
    }
    
    onSpanClick = () => {
        this.props.setDisplayToNone();
    }

    render() {
        const { toggleCatToType } = this.props.state.display;
        const { signs } = this.props.state.signs;
        

        return (
            <div className="signs"
                style={{
                    display: toggleCatToType === 'none' ? 'none' : 'block'
                }}>
                <span onClick={this.onSpanClick}>Назад к типам</span>
                <hr />
                {
                    signs && signs.length > 0 && signs.map((sign, index) => {
                        return <div data-toggle="modal" data-target="#signModal"  className="category-divs" key={index} onClick={this.getSign.bind(this, sign.id)}>
                            <div style={{ background: `center / contain no-repeat url(/images/signs/${index + 1}.png)` }} />
                            <span>{sign.number}</span>
                        </div>
                    })
                }
                <div
                    data-toggle="modal" data-target="#addSignModal"
                    style={{ background: `center / contain no-repeat url(/images/add-sign.png)` }}
                />
            </div >
        )
    }
}
export default connect(
    state => ({
        state
    }),
    dispatch => ({
        setDisplayToNone: () => {
            dispatch({ type: "TOGGLE_TO_CATEGORIES" });
        },
        setSign: (value) => {
            dispatch({ type: "SET_SIGN", value });
        }
    })
)(Signs);
