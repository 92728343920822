import React from 'react'
import {Route, Switch} from 'react-router-dom';

import MapRoadSigns from './components/map/road-signs/MapRoadSigns';
import MapTrafficSigns from './components/map/traffic-signs/MapTrafficSigns';
import Menu from './components/menu/Menu';
import NotFound from './components/NotFound';

export const AppRouting = () => (
    <div>
        <Switch>
            <Route exact path="/" component={MapRoadSigns}/>
            <Route exact path="/map-markers" component={MapRoadSigns}/>
            <Route exact path="/map-traffic" component={MapTrafficSigns}/>
            <Route exact path="/menu" component={Menu}/>
            
            <Route component={NotFound}/>
        </Switch>
    </div>
)