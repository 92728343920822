import React, { Component } from 'react';
import { connect } from 'react-redux'


class SignGaleryModal extends Component {
    constructor() {
        super();

        this.state = {
            categories: [],
            signs: []
        }
    }

    componentDidMount() {
        this.getCategories()
    }

    getCategories = () => {
        fetch(`http://localhost:3000/lib_category`)
            .then((response) => {
                return response.json()
            })
            .then((body) => {
                this.setState({ categories: body })
            })
            .catch((err) => {
                console.log('errooooooooorrrrrrrr ====== ' + err)
            })
    }

    getMarkSigns = (id) => {
        fetch(`http://localhost:3000/marker_signs?marker_id=${id}`)
            .then((response) => {
                return response.json()
            })
            .then((body) => {
                // console.log(body)
                this.setState({ signs: body })
            })
            .catch((err) => {
                console.log('errooooooooorrrrrrrr ====== ' + err)
            })
    }

    onToggleToMarkerTypes = (id) => {
        this.props.toggleToMarkerTypes();
        this.getMarkSigns(id)
    }

    onSignClick = (id) => {
        this.props.setSignId(id)
        this.closePopUp()
    }

    renderMarkerCategories = () => {
        const { categories } = this.state;
        return categories && categories.length > 0 && categories.map((categorie, index) => {
            return <div className="category-divs" key={index}
                onClick={this.onToggleToMarkerTypes.bind(this, categorie.id)}>
                <div style={{ background: `center / contain no-repeat url(/images/categories/${index + 1}.png)` }} >
                    <span>{categorie.name}</span>
                </div>
            </div>
        })
    }

    renderMarkerSigns = () => {
        const { signs } = this.state;
        return signs && signs.length > 0 && signs.map(sign => {
            return <div className="category-divs" key={sign.id}
                onClick={this.onSignClick.bind(this, sign.id)}>
                <div style={{
                    background: `center / contain no-repeat url(/images/signs/${sign.id}.png)`,
                    height: "70px"
                }} >
                </div>
            </div>
        })
    }

    closePopUp = () => {
        document.getElementById("hideGaleryPopUpBtn").click();
    }

    render() {
        const { toggleMarkerCatToType } = this.props.state.display;

        return (
            <div className="modal " id="signGaleryModal">
                <div className="modal-dialog ">
                    <div className="modal-content sign-galery-modal">

                        <div className="modal-header">
                            <h5 className="modal-title">Добавить новый знак</h5>
                            <button id="hideGaleryPopUpBtn"
                                onClick={this.props.toggleToMarkerCategories}
                                type="button" className="close"
                                data-dismiss="modal">
                                &times;
                            </button>
                        </div>
                        <div className="modal-body category-divs">
                            {
                                toggleMarkerCatToType === 'none' ?
                                    <span>Выберите тип знакa</span> :
                                    <span onClick={this.props.toggleToMarkerCategories} style={{ cursor: 'pointer' }}>
                                        Назад к типам
                                    </span>
                            }

                            <hr />
                            {
                                toggleMarkerCatToType === 'none' ?
                                    this.renderMarkerCategories() :
                                    this.renderMarkerSigns()

                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default connect(
    state => ({
        state
    }),
    dispatch => ({

        toggleToMarkerTypes: () => {
            dispatch({ type: "TOGGLE_TO_MARKER_SIGNS" });
        },
        toggleToMarkerCategories: () => {
            dispatch({ type: "TOGGLE_TO_MARKER_CATEGORIES" });
        },
        setSignId: (value) => {
            dispatch({ type: "SET_SIGN_ID", value });
        }
    })
)(SignGaleryModal);
