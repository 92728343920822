import {combineReducers} from 'redux';

import categories from './categories';
import signs from './signsList';
import display from './display';
import sign from './sign';
import ids from './ids';
import markers from './markers';
import coordinates from './coordinates';

export default combineReducers({
    categories,
    signs,
    display,
    sign,
    ids,
    markers,
    coordinates
});