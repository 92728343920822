import React, { Component } from 'react';
import { connect } from 'react-redux'

class AddCategoryModal extends Component {
    constructor() {
        super()

        this.state = {
            name: '',
            image: ''
        }
    }

    onChange = (event) => {
        const target = event.target;
        // const value = target.type === 'checkbox' ? target.checked : target.value;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
        // console.log(this.state)
    }

    addCategory = () => {
        const { name, image } = this.state
        const body = JSON.stringify({ name, image })
        fetch(`http://localhost:3000/lib_category`,
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                }),
                body: body
            }
        )
            .then((response) => {
                return response.json()
            })
            .then((body) => {
                let { categories } = this.props.state.categories;
                categories = categories.concat(body)
                this.props.setCategories(categories)
                this.setState({ name: '', image: '' })
                this.closePopUp()
            })
            .catch((err) => {
                console.log('errooooooooorrrrrrrr ====== ' + err)
            })
    }

    closePopUp = () => {
        document.getElementById("hidePopUpBtn").click();
    }

    render() {
        const { name, image } = this.state
        return (
            <div className="modal" id="addCategoryModal">
                <div className="modal-dialog">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h4 className="modal-title">Добавить новый тип</h4>
                            <button id="hidePopUpBtn" type="button" className="close" data-dismiss="modal">
                                &times;
                            </button>
                        </div>
                        <form>
                            <div className="modal-body form-group">
                                <input
                                    type="text"
                                    name="name"
                                    onChange={this.onChange}
                                    value={name}
                                    className="form-control"
                                    placeholder='Название'
                                    required

                                /> <br />
                                <input
                                    type="text"
                                    name="image"
                                    onChange={this.onChange}
                                    value={image}
                                    className="form-control"
                                    placeholder='Картинка'
                                    required

                                />
                            </div>

                            <div className="modal-footer">
                                <button onClick={this.addCategory} type="button" className="btn btn-success" >Добавить</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        )
    }
}
export default connect(
    state => ({
        state
    }),
    dispatch => ({
        setCategories: (value) => {
            dispatch({ type: "SET_CATEGORIES", value });
        }
    })
)(AddCategoryModal);
