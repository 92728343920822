import React, { Component } from 'react';
import { connect } from 'react-redux'

class Sign extends Component {
    constructor() {
        super()

        this.state = {
            name: '',
            image: '',
            number: '',
            description: '',
        }
    }

    onChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    deleteSign = (id) => {
        fetch(`http://localhost:3000/lib_sign/${id}`,
            {
                method: 'DELETE',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                }),
            }
        )
            .then((response) => response.json())
            .then((body) => {
                console.log(body)
                this.closePopUp()
            })
            .catch((err) => {
                console.log('errooooooooorrrrrrrr ====== ' + err)
            })
        this.props.removeSign(id)
    }

    closePopUp = () => {
        document.getElementById("hideSignPopUpBtn").click();
    }

    render() {
        const { sign } = this.props.state.sign
        return (
            <div className="modal" id="signModal">
                <div className="modal-dialog">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h4 className="modal-title">{sign.name}</h4>

                            <button id="hideSignPopUpBtn" type="button" className="close" data-dismiss="modal">
                                &times;
                            </button>
                        </div>
                        <table className="table ">

                            <tbody>
                                <tr>
                                    <td>
                                        <div
                                            style={{
                                                background: `center / contain no-repeat url(/images/signs/${sign.id}.png)`,
                                                height: '100px'
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Номер знака</td>
                                    <td>{sign.number}</td>
                                </tr>
                                <tr>
                                    <td>Описание</td>
                                    <td>{sign.description}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <button onClick={this.deleteSign.bind(this, sign.id)} type="button" className="btn btn-danger" >
                                            Удалить
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}
export default connect(
    state => ({
        state
    }),
    dispatch => ({
        setCategories: (value) => {
            dispatch({ type: "SET_CATEGORIES", value });
        },
        removeSign: (id) => {
            dispatch({ type: "REMOVE_SIGN", id });
        },
    })
)(Sign);
