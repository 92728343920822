const idsState = {
    categoryId: null,
    markerId: null,
    signId: null,
    trafficSignId: null
};

export default function ids(state = idsState, action) {
    if (action.type === "SET_CATEGORY_ID") {
        return {
            ...state,
            categoryId: action.value,
        };
    } else if (action.type === "SET_MARKER_ID") {
        return {
            ...state,
            markerId: action.value,
        };
    } else if (action.type === "SET_SIGN_ID") {
        return {
            ...state,
            signId: action.value,
        };
    } else if (action.type === "SET_TRAFFIC_SIGN_ID") {
        return {
            ...state,
            trafficSignId: action.value,
        };
    } 
    return state;
}