import React, { Component } from "react";
import { Marker, withLeaflet } from "react-leaflet";
import { Marker as LeafletMarker } from "leaflet-rotatedmarker";
import L from 'leaflet';

const m = <img  alt='marker' src='/images/marker-icons/marker.png' />
const customIcon = L.icon({
    iconUrl: m.props.src,
})
const RotatedMarker = props => {
    const setupMarker = marker => {
        if (marker) {
            if (props.rotationAngle)
                marker.leafletElement.setRotationAngle(props.rotationAngle);
            marker.leafletElement.setRotationOrigin(props.rotationOrigin);
        }
    };

    return <Marker icon={customIcon} ref={el => setupMarker(el)} {...props} />;
};

RotatedMarker.defaultProps = {
    rotationOrigin: "center"
};

export default withLeaflet(RotatedMarker);
