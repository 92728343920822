import React, { Component } from 'react';

import { AppRouting } from './routing';
import Header from './components/Header';
// import "./App.css"

class App extends Component {

  render() {
    return (
      <div className="main-div ">
        <Header />
        <div className='router-content'>
          <AppRouting />
        </div>
      </div>
    )
  }
}

export default App;
