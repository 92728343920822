const markersState = {
    markers: [],
    trafficMarkers: []
};

export default function markers(state = markersState, action) {
    if (action.type === "SET_MARKERS") {
        return {
            ...state,
            markers: action.value,
        };
    } else if (action.type === "REMOVE_MARKER") {
        return {
            ...state,
            markers: state.markers.filter(marker => marker.id !== action.id)
        };
    } else  if (action.type === "SET_TRAFFIC_MARKERS") {
        return {
            ...state,
            trafficMarkers: action.value,
        };
    } else if (action.type === "REMOVE_TRAFFIC_MARKER") {
        return {
            ...state,
            trafficMarkers: state.trafficMarkers.filter(marker => marker.id !== action.id)
        };
    }

    return state;
}