import React, { Component } from 'react';
import { connect } from 'react-redux'

class AddMarkerModal extends Component {
    constructor() {
        super()

        this.state = {
            lat: '',
            lng: '',
            rotation: '',
            errors: {}
        }
    }

    onChange = (event) => {

        const target = event.target;
        let value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    addMarker = () => {
        let { lat, lng, rotation } = this.state;
        const { coordinates } = this.props.state.coordinates
        lat = lat || coordinates.lat
        lng = lng || coordinates.lng

        let errors = {};

        const body = JSON.stringify({
            coordinates: [parseFloat(lat), parseFloat(lng)],
            rotation: parseFloat(rotation)
        })

        if (!lat || !lng) {
            errors.lat = "Объязательное поле";
            errors.lng = "Объязательное поле";
            this.setState({ errors });
        } else {
            fetch(`http://localhost:3000/markers`,
                {
                    method: 'POST',
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    }),
                    body: body
                }
            )
                .then((response) => {
                    return response.json()
                })
                .then((body) => {
                    let { markers } = this.props.state.markers;
                    markers = markers.concat(body)

                    this.props.setMarkers(markers)
                    this.setState({ lat: '', lng: '', rotation: '' })

                })
                .catch((err) => {
                    console.log('errooooooooorrrrrrrr ====== ' + err)
                })
        }
    }

    render() {
        const { addMarkerModal } = this.props.state.display;
        let { lat, lng, rotation, errors } = this.state;
        const { coordinates } = this.props.state.coordinates
        lat = lat || coordinates.lat
        lng = lng || coordinates.lng

        return (
            <div className="marker-modal-style">
                <div className="modal-content" style={{
                    display: addMarkerModal === 'none' ? 'none' : 'block'
                }}>

                    <div className="modal-header">

                        <button type="button"
                            className="close"
                            onClick={this.props.hideAddMarkerModal}>
                            &times;
                    </button>
                    </div>
                    <form>
                        <div className="modal-body form-group">
                            <span className="error-text">{errors.lat}</span>
                            <input
                                type="text"
                                name="lat"
                                onChange={this.onChange}
                                value={lat || ''}
                                className="form-control"
                                placeholder='Широта'
                                required
                            /><br />
                            <span className="error-text">{errors.lng}</span>
                            <input
                                type="text"
                                name="lng"
                                onChange={this.onChange}
                                value={lng || ''}
                                className="form-control"
                                placeholder='Долгота'
                                required
                            /><br />
                            <input
                                type="text"
                                name="rotation"
                                onChange={this.onChange}
                                value={rotation}
                                className="form-control"
                                placeholder='Вращение'
                                required

                            />
                        </div>

                        <div className="modal-footer">
                            <button onClick={this.addMarker}
                                type="button" className="btn btn-success" >
                                Добавить
                        </button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}
export default connect(
    state => ({
        state
    }),
    dispatch => ({
        hideAddMarkerModal: () => {
            dispatch({ type: "HIDE_ADD_MARKER_MODAL" });
        },
        setMarkers: (value) => {
            dispatch({ type: "SET_MARKERS", value });
        }
    })
)(AddMarkerModal);
