import React, { Component } from 'react';
import { connect } from 'react-redux'
import SignGaleryModal from './SignGaleryModal';

class AddMarkerSignModal extends Component {
    constructor() {
        super()

        this.state = {
            name: '',
            description: '',
        }
    }

    onChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
        // console.log(this.state)
    }

    addMarkerSign = () => {
        const {markerId, signId} = this.props.state.ids
        const { name, description } = this.state;

        const body = JSON.stringify({ name, description, marker_id: markerId, sign_id: signId })
        // console.log(body)
        fetch(`http://localhost:3000/marker_signs`,
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                }),
                body: body
            }
        )
            .then((response) => {
                return response.json()
            })
            .then((body) => {
                let {markerSigns} = this.props.state.sign;
                markerSigns = markerSigns.concat(body)

                this.props.setMarkerSigns(markerSigns)
                this.setState({ name: '', description: '' })
                this.closePopUp()

            })
            .catch((err) => {
                console.log('errooooooooorrrrrrrr ====== ' + err)
            })
    }

    closePopUp = () => {
        document.getElementById("hideAddMSPopUpBtn").click();
    }

    render() {
        const { name, description } = this.state
        const { addMarkerSignModal } = this.props.state.display;

        return (
            <div className="marker-modal-style">
                <SignGaleryModal />

                <div className="modal-content" style={{
                    display: addMarkerSignModal === 'none' ? 'none' : 'block'
                }}>

                    <div className="modal-header">
                        <h5 className="modal-title">Добавить новый знак</h5>
                        <button id="hideAddMSPopUpBtn" type="button" className="close" onClick={this.props.hideAddMarkerSignModal}>
                            &times;
                            </button>
                    </div>
                    <form>
                        <div className="modal-body form-group">
                            <input
                                type="text"
                                name="name"
                                onChange={this.onChange}
                                value={name}
                                className="form-control"
                                placeholder='Название'
                                required
                            /><br />
                            <input
                                type="text"
                                name="description"
                                onChange={this.onChange}
                                value={description}
                                className="form-control"
                                placeholder='Описание'
                                required

                            /><br />
                            <input
                                data-toggle="modal" data-target="#signGaleryModal"
                                type="button"
                                value='Выберите знак из библиотеки'
                                className="form-control btn btn-primary"
                                style={{
                                    backgroundColor: 'white',
                                    color: 'black'
                                }}
                            /><br />
                        </div>

                        <div className="modal-footer">
                            <button onClick={this.addMarkerSign} type="button" className="btn btn-success" >Добавить</button>
                        </div>
                    </form>


                </div>
            </div>
        )
    }
}
export default connect(
    state => ({
        state
    }),
    dispatch => ({
        hideAddMarkerSignModal: () => {
            dispatch({ type: "HIDE_ADD_MARKER_SIGN_MODAL" }); 
        },
        setMarkerSigns: (value) => {
            dispatch({ type: "SET_MARKER_SIGNS", value }); 
        }
    })
)(AddMarkerSignModal);
