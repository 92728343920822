import React, { Component } from 'react';

class App extends Component {
 
  render() {
    return (
      <div>
          <h1>not found</h1>
      </div>
    )
  }
}

export default App;
