import React, { Component } from 'react';
import { connect } from 'react-redux'

import Categories from './Categories';
import Signs from './Signs';
import AddCategoryModal from './AddCategoryModal';
import AddSignModal from './AddSignModal';
import Sign from './Sign';

class Menu extends Component {
    constructor() {
        super();

        this.state = {
            categories: []
        }
    }

    render() {
        return (
            <div className="row wrapper menu-component">
                <AddCategoryModal />
                <AddSignModal />
                <Sign />
                <aside className="col-12 col-sm-3 p-0 ">
                    <nav className="navbar navbar-expand-sm  align-items-start flex-sm-column flex-row">
                        <a className="navbar-toggler" data-toggle="" >
                            Библиотека знаков
                        </a>
                        <div className="collapse navbar-collapse sidebar">
                            <ul className="flex-column navbar-nav w-100 justify-content-between">

                                <li className="nav-item">
                                    <a className="nav-link pl-0" >
                                        <span className="">Библиотека знаков</span>
                                    </a>
                                </li>

                            </ul>
                        </div>
                    </nav>
                </aside>
                <main className="col bg-faded py-3">
                    <Categories />
                    <Signs />
                </main>
            </div>
        )
    }
}
export default connect(
    state => ({
        state
    }),
    dispatch => ({
        setCategories: (value) => {
            dispatch({ type: "SET_CATEGORIES", value });
        }
    })
)(Menu);
