import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Map, TileLayer } from 'react-leaflet'

import SignInfoModal from './SignInfoModal'
import AddMarkerModal from './AddMarkerModal'
import AddMarkerSignsModal from './AddMarkerSignsModal'
import RotatedMarker from './RotatedMarker'

class MapMarkerSigns extends Component {
    constructor() {
        super()
        this.state = {
            lat: 53.27851,
            lng: 34.3442081,
            zoom: 13,
        }
    }

    componentDidMount() {
        this.getMarkers()
    }

    getMarkers = () => {
        fetch(`http://localhost:3000/markers`)
            .then((response) => {
                return response.json()
            })
            .then((body) => {
                this.props.setMarkers(body)
            })
            .catch((err) => {
                console.log('errooooooooorrrrrrrr ====== ' + err)
            })
    }

    getSignsByMarkerId = (id) => {

        fetch(`http://localhost:3000/marker_signs?marker_id=${id}`,
            {
                method: 'GET'
            }
        )
            .then((response) => {
                return response.json()
            })
            .then((body) => {
                this.props.setMarkerSigns(body)
            })
            .catch((err) => {
                console.log('errooooooooorrrrrrrr ====== ' + err)
            })
    }

    addMarker = (e) => {
        const lat = parseFloat(e.latlng.lat)
        const lng = parseFloat(e.latlng.lng)
        const coordinates = { lat, lng }
        this.props.setCoordinates(coordinates);
    }

    onMarkerClick = (id) => {
        this.props.showSignInfoModal();
        this.getSignsByMarkerId(id)
        this.props.setMarkerId(id)
        this.props.hideAddMarkerModal()
    }

    onOpenAddMarkerModal = () => {
        this.props.showAddMarkerModal();
        this.props.hideSignInfoModal();
    }

    render() {
        const { lat, lng, zoom } = this.state
        const position = [lat, lng]
        const { markers } = this.props.state.markers;

        return (
            <div className="row wrapper map-component">
                <div className="add-marker-btn">
                    <button className="btn-default btn" onClick={this.onOpenAddMarkerModal}>
                        Добавить новую метку
                    </button>
                </div>
                <SignInfoModal />
                <AddMarkerModal />
                <AddMarkerSignsModal />
                <Map center={position} zoom={zoom} onClick={this.addMarker}>
                    <TileLayer
                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {
                        markers && markers.length > 0 && markers.map(marker => {
                            return <div key={marker.id}>
                                <RotatedMarker
                                    onClick={this.onMarkerClick.bind(this, marker.id)}
                                    position={marker.coordinates}
                                    rotationAngle={marker.rotation}
                                />
                            </div>
                        })
                    }

                </Map>
            </div>
        )
    }
}

export default connect(
    state => ({
        state
    }),
    dispatch => ({
        setMarkers: (value) => {
            dispatch({ type: "SET_MARKERS", value });
        },
        showSignInfoModal: () => {
            dispatch({ type: "SHOW_SIGN_INFO_MODAL" });
        },
        showAddMarkerModal: () => {
            dispatch({ type: "SHOW_ADD_MARKER_MODAL" });
        },
        setMarkerSigns: (value) => {
            dispatch({ type: "SET_MARKER_SIGNS", value });
        },
        setMarkerId: (value) => {
            dispatch({ type: "SET_MARKER_ID", value });
        },
        setCoordinates: (value) => {
            dispatch({ type: "SET_COORDINATES", value });
        },
        hideSignInfoModal: () => {
            dispatch({ type: "HIDE_SIGN_INFO_MODAL" });
        },
        hideAddMarkerModal: () => {
            dispatch({ type: "HIDE_ADD_MARKER_MODAL" });
        },
    })
)(MapMarkerSigns);

