const displayState = {
    toggleCatToType: 'none',
    toggleMarkerCatToType: 'none',
    signInfoModal: 'none',
    addMarkerModal: 'none',
    addMarkerSignModal: 'none',
    trafficMarkerModal: "none",
    trafficSignInfoModal: "none"
};

export default function display(state = displayState, action) {
    if (action.type === "TOGGLE_TO_TYPES") {
        return {
            ...state,
            toggleCatToType: 'block',
        };
    } else if(action.type === "TOGGLE_TO_CATEGORIES") {
        return {
            ...state,
            toggleCatToType: 'none',
        };
    } else if(action.type === "SHOW_SIGN_INFO_MODAL") {
        return {
            ...state,
            signInfoModal: 'block',
        };
    } else if(action.type === "HIDE_SIGN_INFO_MODAL") {
        return {
            ...state,
            signInfoModal: 'none',
        };
    } else if(action.type === "SHOW_ADD_MARKER_MODAL") {
        return {
            ...state,
            addMarkerModal: 'block',
        };
    } else if(action.type === "HIDE_ADD_MARKER_MODAL") {
        return {
            ...state,
            addMarkerModal: 'none',
        };
    } else if(action.type === "SHOW_ADD_MARKER_SIGN_MODAL") {
        return {
            ...state,
            addMarkerSignModal: 'block',
        };
    } else if(action.type === "HIDE_ADD_MARKER_SIGN_MODAL") {
        return {
            ...state,
            addMarkerSignModal: 'none',
        };
    } else if(action.type === "TOGGLE_TO_MARKER_CATEGORIES") {
        return {
            ...state,
            toggleMarkerCatToType: 'none',
        };
    } else if(action.type === "TOGGLE_TO_MARKER_SIGNS") {
        return {
            ...state,
            toggleMarkerCatToType: 'block',
        };
    } else if(action.type === "SHOW_ADD_TRAFFIC_MARKER_MODAL") {
        return {
            ...state,
            trafficMarkerModal: 'block',
        };
    } else if(action.type === "HIDE_ADD_TRAFFIC_MARKER_MODAL") {
        return {
            ...state,
            trafficMarkerModal: 'none',
        };
    } else if(action.type === "SHOW_TRAFFIC_SIGN_INFO_MODAL") {
        return {
            ...state,
            trafficSignInfoModal: 'block',
        };
    } else if(action.type === "HIDE_TRAFFIC_SIGN_INFO_MODAL") {
        return {
            ...state,
            trafficSignInfoModal: 'none',
        };
    }

    return state;
}