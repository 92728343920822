const coordinatesState = {
    coordinates: {},
    trafficCoordinates: {},
};

export default function coordinates(state = coordinatesState, action) {
    if (action.type === "SET_COORDINATES") {
        return {
            ...state,
            coordinates: action.value,
        };
    } else  if (action.type === "SET_TRAFFIC_COORDINATES") {
        return {
            ...state,
            trafficCoordinates: action.value,
        };
    } 

    return state;
}