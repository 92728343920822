import React, { Component } from 'react';
import { connect } from 'react-redux'

class SignInfoModal extends Component {
    constructor() {
        super()

        this.state = {
            name: '',
            image: ''
        }
    }

    removeMarker = () => {
        const { markerId } = this.props.state.ids;

        fetch(`http://localhost:3000/markers/${markerId}`,
            {
                method: 'DELETE',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                }),
            }
        )
            .then((response) => response.json())
            .then((body) => {
                console.log(body)
            })
            .catch((err) => {
                console.log('errooooooooorrrrrrrr ====== ' + err)
            })
        this.props.removeMarker(markerId)
        this.props.hideSignInfoModal()
    }

    removeSign = (id) => {

        fetch(`http://localhost:3000/marker_signs/${id}`,
            {
                method: 'DELETE',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                }),
            }
        )
            .then((response) => response.json())
            .then((body) => {
                console.log(body)
            })
            .catch((err) => {
                console.log('errooooooooorrrrrrrr ====== ' + err)
            })
        this.props.removeMarkerSigns(id)

    }

    onAddMarkerSignsModal = () => {
        this.props.addMarkerSignModal()
    }

    render() {
        const { signInfoModal } = this.props.state.display;
        const { markerSigns } = this.props.state.sign;

        return (
            <div className="marker-modal-style">
                <div className="modal-content" style={{
                    display: signInfoModal === 'none' ? 'none' : 'block'
                }}>

                    <div className="modal-header">
                        <div className="modal-title">
                            <img alt='add' src='/images/add.png'
                                onClick={this.onAddMarkerSignsModal} />
                            <img onClick={this.removeMarker} alt='remove' src='/images/remove.png' />
                        </div>
                        <button type="button"
                            className="close"
                            onClick={this.props.hideSignInfoModal}>
                            &times;
                    </button>
                    </div>
                    {
                        markerSigns.length <= 0 ? <span>Знаков не найдено</span> : <span>Выберите знак</span>
                    }

                    <table className="table modal-body">


                        {
                            markerSigns && markerSigns.length > 0 && markerSigns.map((sign, index) => {
                                return <tbody key={sign.id}>
                                    <tr>
                                        <td rowSpan="2" style={{ textAlign: "center" }}>
                                            <div
                                                style={{
                                                    background: `center / contain no-repeat url(/images/marker-signs/${index+1}.png)`,
                                                    height: '50px',
                                                    width: '50px',
                                                }}
                                            />

                                        </td>
                                        <td>Название</td>
                                        <td>{sign.name}</td>
                                        <td rowSpan="2" valign="middle">
                                            <img onClick={this.removeSign.bind(this, sign.id)} src="/images/remove.png" alt="remove" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Описание</td>
                                        <td>{sign.description || '---'}</td>
                                    </tr>

                                </tbody>

                            })
                        }

                    </table>
                    <hr />
                </div>
            </div>
        )
    }
}
export default connect(
    state => ({
        state
    }),
    dispatch => ({
        hideSignInfoModal: () => {
            dispatch({ type: "HIDE_SIGN_INFO_MODAL" }); 
        },
        addMarkerSignModal: () => {
            dispatch({ type: "SHOW_ADD_MARKER_SIGN_MODAL" }); 
        },
        removeMarker: (id) => {
            dispatch({ type: "REMOVE_MARKER", id });
        },
        removeMarkerSigns: (id) => {
            dispatch({ type: "REMOVE_MARKER_SIGNS", id });
        }
    })
)(SignInfoModal);
