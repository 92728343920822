import React, { Component } from 'react';
import { connect } from 'react-redux'

class AddSignModal extends Component {
    constructor() {
        super()

        this.state = {
            name: '',
            image: '',
            number: '',
            description: '',
        }
    }

    onChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
        // console.log(this.state)
    }

    addCategory = () => {
        const { name, image, number, description } = this.state;
        const category_id = this.props.state.ids.categoryId;

        const body = JSON.stringify({ name, image, number, description, category_id })

        fetch(`http://localhost:3000/lib_sign`,
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                }),
                body: body
            }
        )
            .then((response) => {
                return response.json()
            })
            .then((body) => {
                let { signs } = this.props.state.signs;
                signs = signs.concat(body)

                this.props.setSigns(signs)
                this.setState({ name: '', image: '', number: '', description: '' })
                this.closePopUp()

            })
            .catch((err) => {
                console.log('errooooooooorrrrrrrr ====== ' + err)
            })
    }

    closePopUp = () => {
        document.getElementById("hideSignsPopUpBtn").click();
    }

    render() {
        const { name, image, number, description } = this.state

        return (
            <div className="modal" id="addSignModal">
                <div className="modal-dialog">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h4 className="modal-title">Добавить новый знак</h4>
                            <button id="hideSignsPopUpBtn" type="button" className="close" data-dismiss="modal">
                                &times;
                            </button>
                        </div>
                        <form>
                            <div className="modal-body form-group">
                                <input
                                    type="text"
                                    name="name"
                                    onChange={this.onChange}
                                    value={name}
                                    className="form-control"
                                    placeholder='Название'
                                    required
                                /><br />
                                <input
                                    type="text"
                                    name="image"
                                    onChange={this.onChange}
                                    value={image}
                                    className="form-control"
                                    placeholder='Картика'
                                    required
                                /><br />
                                <input
                                    type="text"
                                    name="number"
                                    onChange={this.onChange}
                                    value={number}
                                    className="form-control"
                                    placeholder='Номер'
                                    required

                                /><br />
                                <input
                                    type="text"
                                    name="description"
                                    onChange={this.onChange}
                                    value={description}
                                    className="form-control"
                                    placeholder='Описание'
                                    required

                                />

                            </div>

                            <div className="modal-footer">
                                <button onClick={this.addCategory} type="button" className="btn btn-success" >Добавить</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        )
    }
}
export default connect(
    state => ({
        state
    }),
    dispatch => ({
        setSigns: (value) => {
            dispatch({ type: "SET_SIGNS_LIST", value });
        }
    })
)(AddSignModal);
