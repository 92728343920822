import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Map, TileLayer, Marker } from 'react-leaflet'
import L from 'leaflet';

import AddTrafficMarker from './AddTrafficMarker'
import TrafficSignInfoModal from './TrafficSignInfoModal'

const m = <img alt='marker' src='/images/marker-icons/traffic-marker.jpg' />
const trafficMarker = L.icon({
    iconUrl: m.props.src,
})

class MapTrafficrSigns extends Component {
    constructor() {
        super()
        this.state = {
            lat: 53.27851,
            lng: 34.3442081,
            zoom: 13,
        }
    }

    componentDidMount() {
        this.getTrafficMarkers()
    }

    getTrafficMarkers = () => {
        fetch(`http://localhost:3000/traffic_markers`)
            .then((response) => {
                return response.json()
            })
            .then((body) => {
                this.props.setTrafficMarkers(body)
            })
            .catch((err) => {
                console.log('errooooooooorrrrrrrr ====== ' + err)
            })
    }

    getTrafficMarkerInfo = (id) => {

        fetch(`http://localhost:3000/traffic_signs?traffic_id=${id}`,
            {
                method: 'GET'
            }
        )
            .then((response) => {
                return response.json()
            })
            .then((body) => {
                this.props.setTrafficSignInfo(body)
            })
            .catch((err) => {
                console.log('errooooooooorrrrrrrr ====== ' + err)
            })
    }

    addTrafficMarker = (e) => {
        const lat = parseFloat(e.latlng.lat)
        const lng = parseFloat(e.latlng.lng)
        const coordinates = { lat, lng }
        this.props.setTrafficCoordinates(coordinates);
    }

    onTrafficMarkerClick = (id) => {
        this.props.showTrafficInfoModal();
        this.getTrafficMarkerInfo(id)
        this.props.setTrafficSignId(id)
        this.props.hideAddTrafficModal()
    }

    onOpenAddTraffickModal = () => {
        this.props.showAddTrafficMarkerModal();
        this.props.hideTrafficSignInfoModal();
    }

    render() {
        const { lat, lng, zoom } = this.state
        const position = [lat, lng]
        const { trafficMarkers } = this.props.state.markers;

        return (
            <div className="row wrapper map-component ">
                <div className="add-marker-btn">
                    <button className="btn-default btn" onClick={this.onOpenAddTraffickModal}>
                        Добавить новый светофор
                    </button>
                </div>
                <TrafficSignInfoModal />
                <AddTrafficMarker />
                <Map center={position} zoom={zoom} onClick={this.addTrafficMarker} >
                    <TileLayer
                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {
                        trafficMarkers && trafficMarkers.length > 0 && trafficMarkers.map(marker => {
                            return <div key={marker.id} >
                                <Marker
                                    onClick={this.onTrafficMarkerClick.bind(this, marker.id)}
                                    position={marker.coordinates}
                                    icon={trafficMarker}
                                />
                                
                            </div>
                        })
                    }

                </Map>
            </div>
        )
    }
}

export default connect(
    state => ({
        state
    }),
    dispatch => ({
        setTrafficMarkers: (value) => {
            dispatch({ type: "SET_TRAFFIC_MARKERS", value });
        },
        showAddTrafficMarkerModal: () => {
            dispatch({ type: "SHOW_ADD_TRAFFIC_MARKER_MODAL" });
        },
        showTrafficInfoModal: () => {
            dispatch({ type: "SHOW_TRAFFIC_SIGN_INFO_MODAL" });
        },
        setTrafficSignInfo: (value) => {
            dispatch({ type: "SET_TRAFFIC_SIGN_INFO", value });
        },
        setTrafficCoordinates: (value) => {
            dispatch({ type: "SET_TRAFFIC_COORDINATES", value });
        },
        setTrafficSignId: (value) => {
            dispatch({ type: "SET_TRAFFIC_SIGN_ID", value });
        },
        hideAddTrafficModal: () => {
            dispatch({ type: "HIDE_ADD_TRAFFIC_MARKER_MODAL" });
        },
        hideTrafficSignInfoModal: () => {
            dispatch({ type: "HIDE_TRAFFIC_SIGN_INFO_MODAL" });
        },
    })
)(MapTrafficrSigns);

