const categoriesState = {
    categories: []
};

export default function categories(state = categoriesState, action) {
    if (action.type === "SET_CATEGORIES") {
        
        return {
            ...state,
            categories: action.value,
        };        
    }
    return state;
}