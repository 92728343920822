import React, { Component } from 'react';
import Slider from "react-slick";
import { connect } from 'react-redux'

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};

class TrafficInfoModal extends Component {
    constructor() {
        super()

        this.state = {
            name: '',
            image: '',
            data: []
        }
    }

    onRemoveTrafficMarker = () => {
        const { trafficSignId } = this.props.state.ids;
        console.log(trafficSignId)

        fetch(`http://localhost:3000/traffic_markers/${trafficSignId}`,
            {
                method: 'DELETE',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                }),
            }
        )
            .then((response) => response.json())
            .then((body) => {
                console.log(body)
            })
            .catch((err) => {
                console.log('errooooooooorrrrrrrr ====== ' + err)
            })
        this.props.removeTrafficMarker(trafficSignId)

    }

    render() {
        const { trafficSignInfoModal } = this.props.state.display;
        const { trafficSignInfo } = this.props.state.sign;

        return (
            <div className="modal" id="trafficInfoModal" style={{
                display: trafficSignInfoModal === 'none' ? 'none' : 'block'
            }}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">

                        <div className="modal-header">
                            <div className="modal-title">
                                <img onClick={this.onRemoveTrafficMarker}
                                    alt='remove' src='/images/remove.png' />
                            </div>
                            <button onClick={this.props.hideTrafficSignInfoModal}
                                type="button" className="close" >
                                &times;
                            </button>

                        </div>

                        <div className="modal-body">
                            {
                                trafficSignInfo.length <= 0 ? <span>Изображений не найдено<br /><br /></span> : <span></span>
                            }

                        </div>
                        <Slider {...settings}>
                            {
                                trafficSignInfo && trafficSignInfo.length > 0 &&
                                trafficSignInfo.map(item => {
                                    return <div key={item.id}

                                    >
                                        <img className="carousel-img" //
                                            src={`/images/traffic-signs/${item.id}.jpg`}
                                            alt={item.id} />
                                    </div>
                                })
                            }
                        </Slider>
                    </div>
                </div>
            </div>

        )
    }
}
export default connect(
    state => ({
        state
    }),
    dispatch => ({
        hideTrafficSignInfoModal: () => {
            dispatch({ type: "HIDE_TRAFFIC_SIGN_INFO_MODAL" });
        },
        removeTrafficMarker: (id) => {
            dispatch({ type: "REMOVE_TRAFFIC_MARKER", id });
        }

    })
)(TrafficInfoModal);

