const signState = {
    sign: [],
    markerSigns: [],
    trafficSignInfo: []
};

export default function signs(state = signState, action) {
    if (action.type === "SET_SIGN") {
        return {
            ...state,
            sign: action.value,
        };
    } else if (action.type === "SET_MARKER_SIGNS") {
        return {
            ...state,
            markerSigns: action.value,
        };
    } else if (action.type === "REMOVE_MARKER_SIGNS") {
        return {
            ...state,
            markerSigns: state.markerSigns.filter(signs => signs.id !== action.id)
        };
    } else if (action.type === "SET_TRAFFIC_SIGN_INFO") {
        return {
            ...state,
            trafficSignInfo: action.value,
        };
    }

    return state;
}