import React, { Component } from 'react';
import { Link } from "react-router-dom";

class Header extends Component {

    render() {
        return (
            <div className='header'>
                <nav className="navbar navbar-expand-md bg-dark navbar-dark">
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="collapsibleNavbar">
                        <ul className="navbar-nav">
                            <li className="nav-item" data-toggle="dropdown">
                                <a className="nav-link">Карта</a>
                            </li>
                            
                            <div className="dropdown-menu">
                                <Link to='/map-markers' className="nav-link">Дорожные знаки</Link>
                                <Link to='/map-traffic' className="nav-link">Светофоры</Link>
                            </div>
                            
                            <li className="nav-item">
                                <Link to='/menu' className="nav-link">Меню</Link>
                            </li>

                        </ul>
                    </div>
                </nav>
            </div>
        )
    }
}

export default Header;
