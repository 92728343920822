const signsListState = {
    signs: [],
    trafficSigns: [],
};
// return {...state, products: state.products.filter(prod => prod._id !== action.id), count: state.count-1};

export default function signs(state = signsListState, action) {
    if (action.type === "SET_SIGNS_LIST") {
        return {
            ...state,
            signs: action.value,
        }
    } else if (action.type === "REMOVE_SIGN") {
        return {
            ...state,
            signs: state.signs.filter(sign => sign.id !== action.id)
        }
    }

    return state;
}