import React, { Component } from 'react';
import { connect } from 'react-redux'

class Categories extends Component {
    constructor() {
        super();

        this.state = {
            categories: []
        }
    }

    componentDidMount() {
        this.getCategories()
    }

    getCategories = () => {
        fetch(`http://localhost:3000/lib_category`)
            .then((response) => {
                return response.json()
            })
            .then((body) => {
                this.props.setCategories(body)
                this.setState({categories: body})
            })
            .catch((err) => {
                console.log('errooooooooorrrrrrrr ====== ' + err)
            })
    }

    getSigns = (id) => {
        fetch(`http://localhost:3000/lib_sign?category_id=${id}`)
            .then((response) => {
                return response.json()
            })
            .then((body) => {
                this.props.setSigns(body)
            })
            .catch((err) => {
                console.log('errooooooooorrrrrrrr ====== ' + err)
            })
    }


    onCategoryClick = (id) => {
        this.props.setDisplayToBlock();
        this.getSigns(id);
        this.props.setCagetoryId(id)
    }

    render() {
        const { categories } = this.props.state.categories;
        const { toggleCatToType } = this.props.state.display;
        // const { categories } = this.state;
        // console.log(categories)
        return (
            <div className="categories"
                style={{
                    display: toggleCatToType === 'none' ? 'block' : 'none'
                }}>
                <h6>Выберите тип знакa</h6>
                <hr />
                {
                    categories && categories.length > 0 && categories.map((categorie, index) => {
                        return <div className="category-divs" key={index}
                            onClick={this.onCategoryClick.bind(this, categorie.id)}>
                            <div style={{ background: `center / contain no-repeat url(/images/categories/${index + 1}.png)` }} >
                            <span>{categorie.name}</span>
                            </div>
                        </div>
                    })
                }
                <div
                    data-toggle="modal" data-target="#addCategoryModal"
                    style={{ background: `center / contain no-repeat url(/images/add-type.png)` }}
                />
            </div >
        )
    }
}
export default connect(
    state => ({
        state
    }),
    dispatch => ({
        setCategories: (value) => {
            dispatch({ type: "SET_CATEGORIES", value });
        },
        setDisplayToBlock: () => {
            dispatch({ type: "TOGGLE_TO_TYPES" });
        },
        setSigns: (value) => {
            dispatch({ type: "SET_SIGNS_LIST", value });
        },
        setCagetoryId: (value) => {
            dispatch({ type: "SET_CATEGORY_ID", value });
        },
    })
)(Categories);
